/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "./layout.css"
import Header from "./header"
import ScrollToTop from "./ScrollToTop"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="d-flex flex-column container container-main p-0">
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <ScrollToTop />
      <main className="content-main p-4">{children}</main>
      <footer className="text-center footer-main">
        <span className="text-muted">
          {new Date().getFullYear()} - built with{" "}
          <a href="https://www.gatsbyjs.com">Gatsby</a>
        </span>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const MemImgLogo = () => (
  <StaticImage
    className="d-inline-block me-2 rounded"
    src="../images/green.png"
    alt=""
    width={34}
    height={34}
  />
)

export default React.memo(MemImgLogo)

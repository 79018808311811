import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
//import { StaticImage } from "gatsby-plugin-image"
import MemImgHead from "./MemImgHead"
import MemImgLogo from "./MemImgLogo"
//import headImg from "../images/head.jpg"
//import logoImg from "../images/green.png"

const Header = ({ siteTitle }) => {
  // const [value, setValue] = React.useState(0)
  // React.useEffect(() => {
  //   setInterval(() => setValue(prev => prev + 1), 1000)
  // }, [])
  return (
    <header>
      {/* <StaticImage className="header-image" src="../images/head.jpg" alt="" /> */}
      <MemImgHead />
      {/* <div>{value}</div> */}
      <nav
        className="navbar navbar-expand-sm navbar-dark"
        style={{ backgroundColor: "#66b" }}
      >
        <div className="container-fluid">
          <Link to="/" className="navbar-brand d-flex align-items-center">
            <MemImgLogo />
            {/* <StaticImage
              className="d-inline-block me-2 rounded"
              src="../images/green.png"
              alt=""
              width={34}
              height={34}
            /> */}
            {siteTitle}
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="navbar-nav">
              <Link className="nav-link" activeClassName="active" to="/">
                Home
              </Link>
              <Link className="nav-link" activeClassName="active" to="/about/">
                About
              </Link>
              <Link
                className="nav-link"
                activeClassName="active"
                to="/portfolio/"
              >
                Portfolio
              </Link>
              <Link
                className="nav-link"
                activeClassName="active"
                to="/contact/"
              >
                Contact
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

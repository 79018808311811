/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min.js"
import "@popperjs/core/dist/umd/popper.min.js"

import * as React from "react"
import Layout from "./src/components/layout"

//export const wrapPageElement = ({ element }) => <Layout>{element}</Layout>
export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
)

/* EOF */

import React, { useEffect, useState } from "react"

export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false)

  // Top: 0 takes us all the way back to the top of the page
  // Behavior: smooth keeps it smooth!
  const scrollToTop = e => {
    e.target.blur()
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }

    window.addEventListener("scroll", toggleVisibility)

    return () => window.removeEventListener("scroll", toggleVisibility)
  }, [])

  return (
    <>
      {isVisible && (
        <button
          className="btn btn-primary rounded-circle"
          type="button"
          onClick={scrollToTop}
          style={{
            position: "fixed",
            bottom: 30,
            right: 30,
            zIndex: 2,
            width: 56,
            height: 56,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <ChevronUp color="white" size={20} /> react-bootstrap-icons */}
          <svg viewBox="0 0 512 512" fill="#eee" height={30} width={30}>
            <path
              fill="none"
              stroke="#eee"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={48}
              d="M112 328l144-144 144 144"
            />
          </svg>
        </button>
      )}
    </>
  )
}
